<template>
  <div>
    <router-view></router-view>
    <Alerts/>
  </div>
</template>

<script>
import Alerts from "./views/Core/Alerts.vue";

export default {
  name: 'App',
  components: {Alerts},
  metaInfo: {
    title: 'Publisher.Klipy.Com',
    titleTemplate: '%s | Publisher.Klipy.Com'
  },
}
</script>

<style lang="scss">
@import 'assets/sass/app';
</style>
